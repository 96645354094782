<template>
  <layout-drawer-dialog @close="cancel">
    <v-card flat tile>
      <v-card-title>
        <v-btn
          icon
          class="mr-3"
          @click="cancel"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        Add a New Collection
      </v-card-title>
      <v-card-text>
        <v-alert type="info" text border="left">
          <p>When you enter a new collection, fireful will check if the collection already exists in firestore and proceed to fetch some example documents and help you setup the schema model.</p>
          <p>If the collection does not exist in firestore, or the data model is too different across documents the model will be up to you to define.</p>
        </v-alert>
        <collection-setup
          @saved="$router.push({ name: 'collection', params: { collectionId: $event } })"
        />
      </v-card-text>
    </v-card>
  </layout-drawer-dialog>
</template>

<script>
export default {
  name: 'NewCollection',
  methods: {
    cancel() {
      this.$router.push({ name: 'collections' })
    }
  }
}
</script>
